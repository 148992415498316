import React from 'react'
import { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Video from '../../images/pumaCrystalline/CRYSTALLINE_16x9_MASTER_220625.mp4'
import Video2 from '../../images/pumaCrystalline/STARDUST_16x9_MASTER_BLACK_260622.mp4'
import VideoMobile from '../../images/pumaCrystalline/CRYSTALLINE_9x16_MASTER_220625-2.mp4'
import VideoMobile2 from '../../images/pumaCrystalline/STARDUST_AWARENESS_MASTER_BLACK_250622-2.mp4'
import Photo1 from '../../images/pumaCrystalline/2022_05_23 - Puma0004_0270.jpg'
import Photo2 from '../../images/pumaCrystalline/2022_05_23 - Puma0004_0109.jpg'
import Photo3 from '../../images/pumaCrystalline/2022_05_23 - Puma - 0456.jpg'
import Photo4 from '../../images/pumaCrystalline/2022_05_23 - Puma - 0421-2.jpeg'
import Photo5 from '../../images/pumaCrystalline/2022_05_23 - Puma - 0407.jpeg'
import Photo6 from '../../images/pumaCrystalline/2022_05_23 - Puma - 0357.jpeg'

function PumaCrystalline () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const isMobile = window.innerWidth <= 500

    if (isMobile) {
        return (
            <><><div className='PumaCrystalline-page' style={{ backgroundColor: 'black' }}>
                <video controls={true} autoPlay={false} loop={true} playsinline="playsinline" className='my-5'
                    style={{
                        position: "relative",
                        width: "100%",
                        maxHeigth: "30%",
                        objectFit: "contain",
                        zIndex: "1"
                    }}>
                    <source src={VideoMobile} type="video/mp4" />
                </video>
            </div>
                <div className='PumaCrystalline-page' style={{ backgroundColor: 'black' }}>
                    <video controls={true} autoPlay={false} loop={true} playsinline="playsinline" className='my-5'
                        style={{
                            position: "relative",
                            width: "100%",
                            maxHeigth: "30%",
                            objectFit: "contain",
                            zIndex: "1"
                        }}>
                        <source src={VideoMobile2} type="video/mp4" />
                    </video>
                </div></><h1 className="gallery p-5 m-0">Photo gallery</h1><Carousel className="py-5" style={{ backgroundColor: "black" }}>

                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src={Photo1}
                            alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src={Photo2}
                            alt="Second slide" />
                    </Carousel.Item>
                    <Carousel.Item  interval={4000}>
                        <img
                            className="d-block w-100"
                            src={Photo3}
                            alt="Third slide" />
                    </Carousel.Item>
                </Carousel>
                
                <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brasil feat Isis Valverde</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: PUMA Stardust and PUMA</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>Introducing PUMA Stardust, the latest collection featuring global supermodel and actress Isis Valverde. This campaign embodies aspiration, fun, and femininity, set against the backdrop of the breathtaking beaches of Rio de Janeiro. The imagery showcases Isis engaging in playful exercises, perfectly complementing the energetic spirit of the collection.<br/>
                The graphic design elements cleverly intertwine fonts, assets, and dynamic movement, creating visually captivating layers that enhance the presence of the supermodel within the scenery. Clean art direction emphasizes the bold blue skies characteristic of Rio's landscape, adding a touch of vibrancy to the overall aesthetic.
                </text>
              </div>
            </div>
            
            </>
                
        )
    } else {
            return (
                <><><div className='PumaCrystalline-page' style={{ backgroundColor: 'black' }}>
                    <video controls={true} autoPlay={false} loop={true} playsinline="playsinline"
                        style={{
                            position: "relative",
                            width: "100%",
                            maxHeigth: "30%",
                            objectFit: "contain",
                            zIndex: "1"
                        }}>
                        <source src={Video} type="video/mp4" />
                    </video>
                </div>
                    <div className='PumaCrystalline-page pb-5' style={{ backgroundColor: 'black' }}>
                        <video controls={true} autoPlay={false} loop={true} playsinline="playsinline" className='pb-5'
                            style={{
                                position: "relative",
                                width: "100%",
                                maxHeigth: "30%",
                                objectFit: "contain",
                                zIndex: "1"
                            }}>
                            <source src={Video2} type="video/mp4" />
                        </video>
                    </div>
                    
                    </><h1 className="gallery p-5 m-0">Photo gallery</h1><Carousel className="py-5" style={{ backgroundColor: "black" }}>

                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-50 mx-auto"
                                src={Photo4}
                                alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-50 mx-auto"
                                src={Photo5}
                                alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-50 mx-auto"
                                src={Photo6}
                                alt="Third slide" />
                        </Carousel.Item>
                    </Carousel>
                    
                    <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brasil feat Isis Valverde</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: PUMA Stardust and PUMA</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>Introducing PUMA Stardust, the latest collection featuring global supermodel and actress Isis Valverde. This campaign embodies aspiration, fun, and femininity, set against the backdrop of the breathtaking beaches of Rio de Janeiro. The imagery showcases Isis engaging in playful exercises, perfectly complementing the energetic spirit of the collection.<br/>
                The graphic design elements cleverly intertwine fonts, assets, and dynamic movement, creating visually captivating layers that enhance the presence of the supermodel within the scenery. Clean art direction emphasizes the bold blue skies characteristic of Rio's landscape, adding a touch of vibrancy to the overall aesthetic.
                </text>
              </div>
            </div>
            </>
            )
    }
    
}

export default PumaCrystalline