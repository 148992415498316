import React from 'react'

import './Footer.css'

function Footer () {
    return (
        <div className='footer pb-3'>
            <a href='#top' className='d-flex  justify-content-center mb-5 pt-5'> Back to top
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-MIQ0s_eSmecjzAKkv9MefEwZZ5nNx-K2pT2gbyNjlLZsChc85JKOS1q459L1AcMQDUM&usqp=CAU' alt='arrow-up-icon'/>
            </a>
            <p className='d-flex  justify-content-center'>Copyright © 2021 Fernanda Hashimoto. All rights reserved.</p>
        </div>
    )
}

export default Footer