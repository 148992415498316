import React from 'react'
import { useEffect } from 'react'

import LuanaLelisPage from '../../images/luanaLelis/LuanaLelis_page.png'

function LuanaLelis() {

    useEffect(() => {
       window.scrollTo(0, 0)
    }, [])

    return (
        <div className='luanalelis-page'>
            <img src={LuanaLelisPage} alt='luca images' style={{ width: '100vw' , height: 'auto' }} />

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>Luana Lelis</h3>
              <h5 style={{marginTop: '20px'}}>Project: Logo Design / Branding</h5>
              <h5>Services: Graphic Design</h5>
              <div style={{marginTop: '20px'}}>
                <text>Luana Lelis embodies the unapologetic confidence of big, pinkish, fluffy lips that defy convention. Our mission is to encapsulate the feminine elegance inherent in every woman.<br/>
                The visual identity of Luana Lelis marries modernity with timeless sophistication. Our logo employs skeuomorphic elements, blending seamlessly with an art deco font reminiscent of classic cinema. This fusion creates a captivating aesthetic that transcends eras.<br/>
                Luana Lelis represents a timeless allure, offering enduring beauty akin to permanent makeup. Our brand is not just a trend; it's a statement of enduring style and grace.
                </text>
              </div>
            </div>
        </div>
    )
}

export default LuanaLelis