import React from 'react'
import { BrowserRouter, Route , Switch} from 'react-router-dom'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import NavbarResponsive from './navbar/Navbar';
import ListWorks from './works/ListWorks'
import Footer from './footer/Footer'
import About from './about/About';

import RDM from './works/individualWorksPage/Rdm'
import Starry from './works/individualWorksPage/Starry'
import Anis from './works/individualWorksPage/Anis'
import Lumini from './works/individualWorksPage/Lumini';
import Luca from './works/individualWorksPage/Luca';
import LuanaLelis from './works/individualWorksPage/LuanaLelis';
import TravelFleek from './works/individualWorksPage/TravelFleek';
import Rovers from './works/individualWorksPage/Rover';
import Puma from './works/individualWorksPage/Puma';
import PumaCrystalline from './works/individualWorksPage/PumaCrystalline';
import PumaMayze from './works/individualWorksPage/PumaMayze'
import PumaEMJ from './works/individualWorksPage/PumaEMJ'
import Smc from './works/individualWorksPage/Smc';
import Pingoo from './works/individualWorksPage/Pingoo'

function App() {
  return (
    <BrowserRouter>
      <NavbarResponsive/>
      <Switch>
      <Route exact path='/' component={ListWorks} />
      <Route exact path='/about' component={About} />
        <Route exact path='/puma-mayze' component={PumaMayze} />
        <Route exact path='/puma-elas-movem-juntas' component={PumaEMJ} />
        <Route exact path='/rdm' component={RDM} />
        <Route exact path='/starry' component={Starry} />
        <Route exact path='/puma-crystalline' component={PumaCrystalline} />
        <Route exact path='/puma' component={Puma} />
        <Route exact path='/anis' component={Anis} />
        <Route exact path='/lumini' component={Lumini} />
        <Route exact path='/luca' component={Luca} />
        <Route exact path='/luana-lelis' component={LuanaLelis} />
        <Route exact path='/travel-fleek' component={TravelFleek} />
        <Route exact path='/rovers' component={Rovers} />
        <Route exact path='/smc' component={Smc} />
        <Route exact path='/pingoo' component={Pingoo} />
      </Switch>
      
      <Footer />
    </BrowserRouter>
  );
}

export default App;

// CORREÇÕES A FAZER:
// RDM
// STARRY
// ANIS
// EBOOK  
// TRAVELFLEEK

//FINALIZADO:
// ALGO
// LUMINI
// LUCA