import React, { useEffect } from 'react'

import Fernanda from '../images/Fernanda.jpg'
import IllustratorIcon from './images/illustrator-2.png'
import Photoshop from './images/photoshop.png'
import InDesign from './images/indesign.png'
import AfterEffects from './images/after-effects.png'
import XD from './images/xd.png'
import ATeT from './images/at&t black.png'
import Cepam from './images/cepam_black.png'
import HypeEmpire from './images/Hype-Empire.png'
import LatVC from './images/LATVC_Secondary_Logo_RGB.png'
import Luca from './images/logohorizontal__black.png'
import Lumini from './images/LUMINI_pb.png'
import OpticasPt from './images//Op-01.png'
import Puma from './images/puma-se-seeklogo.com-01.png'
import RDM from './images/RDM_icon_black.png'
import SMC from './images/SMC_logo_tiktok_pb.png'
import Starry from './images/1280px-Starry_Internet_logo_black.png'
import TravelFleek from './images/TF_portfolio-05.png'

import CellphoneIcon from '../images/icons/phone-call-01 2.svg'
import EmailIcon from '../images/icons/email-white.svg'
import LinkedinIcon from '../images/icons/linkedin_white.svg'

import './About.css'

function About() {

    useEffect(() => {
       window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div className='about-page d-flex row align-items-center' style={{ backgroundColor: '#DDDD' }}>
            <p className='col'>
                <div className='text caps'>
                    Hey there!
                    <br />
                </div>
                <div className='text'>
                    My name is Fernanda Hashimoto.
                    <br />
                </div>
                <div className='text'>
                    I’m an Art Director and Associate Creative Director, with over 7 years of experience in multi-disciplinary design from Industrial Design to Graphic Design.
                    <br />
                </div>
                <div className='text'>
                    Flexible in nature, adaptable, ﬁnd pleasure in leading and collaborating with other creatives, I have a passion for creating ideas and resolving solutions.
                </div>
            </p>
            <img src={Fernanda} alt='fernanda img' className='fernada-img' />
        </div>
        <div className='skills d-flex justify-content-center pt-5 pb-3' style={{ backgroundColor: '#EBEBEB' }}>
                <button type="button" id="skills" className="btn px-4">Skills</button>
        </div>
        <div className='tools d-flex justify-content-around pb-5 pt-2' style={{ backgroundColor: '#EBEBEB' }}>
            <div className='tools-box d-flex justify-content-around align-items-center pb-5 p-5'>
                <img className='illustrator'  src={IllustratorIcon}/>
                <img className='photoshop'  src={Photoshop}/>
                <img className='indesign'  src={InDesign}/>
                <img className='aftereffects'  src={AfterEffects}/>
                <img className='xd' src={XD}/>
            </div>
        </div>
        <div className='clients d-flex justify-content-center p-5' style={{ backgroundColor: '#DDDD' }}>
            <button type="button" id="skills" className="btn px-4">Clients</button>
        </div>
        <div className='clients-logo d-flex justify-content-around pb-5' style={{ backgroundColor: '#DDDD' }}>
            <div className='logos d-flex justify-content-around align-items-center flex-wrap'>
              <div className='logos-1 d-flex justify-content-around align-items-center col-11 p-3'>
                <img id='atet' className='p-4' src={ATeT}/>
                <img id='cepam' className='p-4' src={Cepam}/>
                <img id='hypeEmpire' className='p-4' src={HypeEmpire}/>
                <img id='latvc' className='p-4' src={LatVC}/>
              </div>  
              <div className='logos-2 d-flex justify-content-around align-items-center col-11 p-3'>
                <img id='luca' className='p-4' src={Luca}/>
                <img id='lumini' className='p-4' src={Lumini}/>
                <img id='opticasPt' className='p-4' src={OpticasPt}/>
                <img id='puma' className='p-4' src={Puma}/>
              </div>
              <div className='logos-3 d-flex justify-content-around align-items-center col-11'>
                <img id='rdm' className='p-4'  src={RDM}/>
                <img id='smc' className='p-4' src={SMC}/>
                <img id='starry' className='p-4' src={Starry}/>
                <img id='tf' className='p-4' src={TravelFleek}/>
              </div>  
            </div>
        </div>
        <div className='contact d-flex justify-content-center p-5' style={{ backgroundColor: '#EBEBEB' }}>
            <button type="button" id="skills" className="btn px-4">Contact</button>
        </div>

            <div className='contact-page d-flex justify-content-center flex-wrap' style={{ backgroundColor: '#EBEBEB' }}>
<div className='contact-info w-100'>
  <div className='contact-cellphone d-flex mt-5'>
      <img src={CellphoneIcon} alt='cellphone icon' />
      <a href="tel:+351 932 538 388"
      className='a' id='cel'>
      +351 932 538 377
      </a>
  </div>

  <div className='contact-email d-flex mt-4'>
      <img src={EmailIcon} alt='email icon' />
       <a href="mailto:fernandahashimoto28@gmail.com"
       className='a' id='email'>
       fernandahashimoto28@gmail.com
      </a>
  </div>

  <div className='contact-linkedin d-flex mt-4 mb-5 pb-5'>
      <img src={LinkedinIcon} alt='linkedin icon' />
      <a href="https://www.linkedin.com/in/fernanda-hashimoto-407004122/" 
        target="_blank" rel="noopener noreferrer" 
        className='a pb-5 mb-5' id='linkedin'>
        /linkedin.com/FernandaHashimoto
      </a>
  </div>
</div>
</div>
        </>
    )
}

export default About
