import React from 'react'
import { useEffect } from 'react'

import StarryPage from '../../images/starry/stary_page.png'
import Video from '../../images/starry/Starry_iMac.mp4'

function Starry () {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className='starry-page'>
            {/* TRANSAÇÃO DAS IMAGENS */}
            <img src={StarryPage} alt='all images' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} />

            <video autoPlay={true} loop={true} muted="muted" playsInline="playsInline" loading="lazy"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1" }}>
             <source src={Video} type="video/mp4" />
            </video>

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>Starry Internet</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: Happy Internet</h5>
              <h5>Services: Graphic Design / Art Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>Starry is an internet provider that beams internet through the sky as fast as fiber. This campaign showcases the astronaut as the brand mascot in various daily situations, engaging with the internet in fun and innovative ways. With "Happy Interneting," accessing the internet has never been easier.<br/>
                As a graphic designer and art director, my role is to craft the visual aesthetic, collaborating closely with retouching designers to ensure consistency with the client's identity across all touchpoints.
                </text>
              </div>
            </div>
        </div>
    )
}

export default Starry