import React from 'react'
import { useEffect } from 'react'

import Video from '../../images/rdm/1_Mockup_1.mp4'
import Mockup from '../../images/rdm/RDM_02.png'
import LogoGif from '../../images/rdm/RDM_03.gif'
import BlackPacking from '../../images/rdm/RDM_04.png'
import Cards from '../../images/rdm/RDM_05.png'
import SignatureGif from '../../images/rdm/RDM_06.gif'


function RDM () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <div className='rdm-page'>
         <video autoPlay={true} loop={true} muted="muted" playsinline="playsinline"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1" }}>
         <source src={Video} type="video/mp4" />
         </video>

         <img src={Mockup} alt='mockup' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} loading="lazy"/>

         {/* FORMATO ESTA COM BORDA */}
         <img src={LogoGif} alt='logo-gif' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} />      
         <img src={BlackPacking} alt='black-packing' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} loading="lazy"/>
         <img src={Cards} alt='cards' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} loading="lazy"/>

         {/* NÃO TA CENTRALIZADO */}
         <img src={SignatureGif} alt='signature-gif' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} />      
          
         <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>Robbins Digital Media</h3>
              <h5 style={{marginTop: '20px'}}>Project: Redesign / Logo Design</h5>
              <h5>Services: Graphic Design</h5>
              <div style={{marginTop: '20px'}}>
                <text>The production company Robbins Digital Media, known as RDM, boasts over 10 years of expertise collaborating with luxury brands to craft compelling videos and content.<br/>
                The recent redesign of their logo presented a significant opportunity to reposition the brand in line with its luxury and elegance. The new logo embodies sophistication and refinement, characterized by thin lines and expressive curves that evoke movement and dynamism.
Incorporating a serif font adds personality and a touch of fashion reminiscent of high-end magazines, while the playful arrangement of the letters "RDM" in the icon reflects a joyful and creative spirit.
                </text>
              </div>
            </div>
        </div>
    )
}

export default RDM