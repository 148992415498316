import React from 'react'
import { useEffect } from 'react'
import PumaArtBoard from '../../images/puma/Prancheta 1.png'
import BM from '../../images/puma/01-BM.gif'
import NIC from '../../images/puma/02-NIC.gif'
import ISA from '../../images/puma/03-ISA_1.gif'
import DJ from '../../images/puma/04-DJ_1.gif'
import MALIA from '../../images/puma/05-MALIA_1.gif'
import TAMI from '../../images/puma/06- TAMIRES.gif'
import ISIS from '../../images/puma/07-ISIS.gif'

function Puma () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <div className='puma-page'>

         <img src={PumaArtBoard} alt='mockup' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}}/> 
         
         <a href='https://www.instagram.com/p/Caj8RSDqHhj/' target="_blank">
            <img src={BM} alt='bruna marquezine' style={{ width: '100vw'}}/>
         </a>
         
         <a href='https://www.instagram.com/p/CamdeLHq7wN/' target="_blank">
            <img src={NIC} alt='nic lima' style={{ width: '100vw'}}/>
         </a>

         <a href='https://www.instagram.com/p/CapC-L9o0En/' target="_blank">
            <img src={ISA} alt='isadora pacheco' style={{ width: '100vw'}}/>
         </a>

         <a href='https://www.instagram.com/p/CarqRMZozBI/' target="_blank">
            <img src={DJ} alt='djenifer arlond' style={{ width: '100vw'}}/>
         </a>

         <a href='https://www.instagram.com/p/CauVOeHqPR4/' target="_blank">
            <img src={MALIA} alt='malia' style={{ width: '100vw'}}/>
         </a>

         <a href='https://www.instagram.com/p/CawvClRKzUS/' target="_blank">
            <img src={TAMI} alt='tamires dias' style={{ width: '100vw'}}/>
         </a>  

         <a href='https://www.instagram.com/p/CazUlK-ojws/' target="_blank">
            <img src={ISIS} alt='tamires dias' style={{ width: '100vw'}}/>
         </a>

         <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brazil</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: Women’s Day</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>The Women's Day campaign unites eight icons, ranging from global actresses to championship-winning athletes, who come together to inspire women worldwide to embrace exercise for enjoyment. Spearheaded by PUMA Global under the banner "She Moves Us," this movement gained momentum in Brazil, advocating the powerful notion that women move forward together.<br/>
                The campaign celebrates the collective strength of women, emphasizing that behind every remarkable woman stand others whose shared strength propels them to greater heights. It underscores the belief that when women support each other, their potential knows no bounds.
                </text>
              </div>
            </div>

        </div>
    )
}

export default Puma