import React from 'react'
import { useEffect } from 'react'

import LuminiPage from '../../images/lumini/lumini_page.png'
import Unpack from '../../images/lumini/led it be animada.mp4'

function Lumini () {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className='lumini page' style={{ marginBottom: '-7px' , 'backgroundColor': 'black'}}>
            <img src={LuminiPage} alt='lumini page' style={{ width: '100vw' , height: 'auto'}} />

            <video autoPlay={true} loop={true} muted="muted" playsinline="playsinline" loading="lazy"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1" }}>
             <source src={Unpack} type="video/mp4" />
            </video>

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>Lumini Lighting Solutions</h3>
              <h5 style={{marginTop: '20px'}}>Project: Packaging Design</h5>
              <h5>Services: Product Designer</h5>
              <div style={{marginTop: '20px'}}>
                <text>Lumini is a contemporary lighting company that fluently communicates with architects and designers.<br/>
                My career journey began as a designer at Lumini, providing me with invaluable opportunities for growth and learning within the industry. During my time there, I actively engaged in the creative process and contributed to the development of new products.<br/>
                One notable project I undertook was the redesign of the packaging for Lumini's best-selling products. In this endeavor, I transitioned the packaging material from foam to paper, with the primary goals of reducing plastic waste, optimizing production costs, and enabling the reuse of the same packaging for various luminaire variations.
                </text>
              </div>
            </div>
        </div>
    )
}

export default Lumini