import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

import Header from '../header/Header'
import './ListWorks.css'

import pumaMayze from '../images/pumaMayze/PumaxBruna_06_1418_R1.png'
import PumaEMJ from "../images/pumaEMJ/Screenshot 2022-11-02 at 19.12.04.png"
import pumaCrystalline from "../images/pumaCrystalline/Screenshot 2022-11-02 at 19.01.56.png"
import puma from "../images/puma/Screenshot 2022-11-02 at 19.03.13.png"
import starry from "../images/starry/Starry_color.png"
import rdm from "../images/rdm/Screenshot 2022-11-02 at 19.14.13.png"
import travelFleek from "../images/travelFleek/TF_portfolio_color.png"
import lumini from "../images/lumini/piccolo_R1.png"
import anis from "../images/anis/Screenshot 2022-11-02 at 18.53.50-1.png"
import luca from "../images/luca/Screenshot 2022-11-02 at 18.53.50-2.png"
import luanaLelis from "../images/luanaLelis/luana_lelis.png"
import rovers from "../images/rovers/rovers.png"
import smc from "../images/smc/web-mockup-V3-right.png"
import Pingoo from "../images/pingoo/Pingoo-portfolio-capa.jpg"

function ListWorks () {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isMobile = window.innerWidth <= 500

    if (isMobile) {
    return(
        <>
        <Header /> 
        <div className='works d-flex'>
            <div className='links col-10 col-sm-12 col-md-12 m-0 p-0'>
                <Link to='/starry'>
                    <img className='starry' src={starry} alt='starry' />
                </Link>

                <Link to='/puma-elas-movem-juntas'>
                    <img className='pumaEMJ' src={PumaEMJ} alt='pumaEMJ' />
                </Link>

                <Link to='/puma-mayze'>
                    <img className='pumamayze' src={pumaMayze} alt='pumamayze' />
                </Link>

                <Link to='/lumini'>
                    <img className='lumini' src={lumini} alt='lumini' />
                </Link>

                <Link to='/pingoo'>
                    <img className='pingoo' src={Pingoo} alt='pingoo' />
                </Link>
                
                <Link to='/rdm'>
                    <img className='rdm' src={rdm} alt='rdm' />
                </Link>

                <Link to='/travel-fleek'>
                    <img className='travelFleek' src={travelFleek} alt='travel-fleek' />
                </Link>

                <Link to='/puma-crystalline'>
                    <img className='pumacrystalline' src={pumaCrystalline} alt='pumaCrystalline' />
                </Link>

                <Link to='/anis'>
                    <img className='anis' src={anis} alt='anis' loading="lazy" />
                </Link>

                <Link to='/luca'>
                    <img className='luca' src={luca} alt='luca' loading="lazy" />
                </Link>

                <Link to='/puma'>
                    <img className='puma' src={puma} alt='puma' />
                </Link>

                <Link to='/luana-lelis'>
                    <img className='luanaLelis' src={luanaLelis} alt='luana-lelis' />
                </Link>

                <Link to='/smc'>
                    <img className='smc' src={smc} alt='suck-my-chic'/>
                </Link>

                <Link to='/rovers'>
                    <img className='rovers' src={rovers} alt='rovers' loading="lazy" />
                </Link>
            </div>
        </div></>
    )
    } else {
        return(
            <>
            <Header /> 
            <div className='works d-flex'>
                <div className='links col-10 col-sm-12 col-md-12 m-0 p-0'>
                    <Link to='/starry'>
                        <img className='starry' src={starry} alt='starry' />
                    </Link>
    
                    <Link to='/puma-elas-movem-juntas'>
                        <img className='pumaEMJ' src={PumaEMJ} alt='pumaEMJ' />
                    </Link>
    
                    <Link to='/puma-mayze'>
                        <img className='pumamayze' src={pumaMayze} alt='pumamayze' />
                    </Link>
    
                    <Link to='/lumini'>
                        <img className='lumini' src={lumini} alt='lumini' />
                    </Link>

                    <Link to='/pingoo'>
                        <img className='pingoo' src={Pingoo} alt='pingoo' />
                    </Link>
    
                    <Link to='/rdm'>
                        <img className='rdm' src={rdm} alt='rdm' />
                    </Link>
    
                    <Link to='/travel-fleek'>
                        <img className='travelFleek' src={travelFleek} alt='travel-fleek' />
                    </Link>
    
                    <Link to='/puma-crystalline'>
                        <img className='pumacrystalline' src={pumaCrystalline} alt='pumaCrystalline' />
                    </Link>
    
                    <Link to='/anis'>
                        <img className='anis' src={anis} alt='anis' loading="lazy" />
                    </Link>
    
                    <Link to='/luca'>
                        <img className='luca' src={luca} alt='luca' loading="lazy" />
                    </Link>
    
                    <Link to='/puma'>
                        <img className='puma' src={puma} alt='puma' />
                    </Link>
    
                    <Link to='/luana-lelis'>
                        <img className='luanaLelis' src={luanaLelis} alt='luana-lelis' />
                    </Link>
    
                    <Link to='/smc'>
                        <img className='smc' src={smc} alt='suck-my-chic' />
                    </Link>
    
                    <Link to='/rovers'>
                        <img className='rovers' src={rovers} alt='rovers' loading="lazy" />
                    </Link>
                </div>
            </div></>
        )
    }
}

export default ListWorks