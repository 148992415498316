import React from 'react'
import { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel'

import VideoMobile from '../../images/pumaEMJ/PUMA_SAFARI_MOBILE.mp4'
import Video from '../../images/pumaEMJ/PUMA SAFARI HERO MASTER 16x9_15_220921.mp4'

import Photo1 from '../../images/pumaEMJ/163a066c2b2fcd.jpg'
import Photo2 from '../../images/pumaEMJ/163a0670906a3d.jpg'
import Photo3 from '../../images/pumaEMJ/163a066b985c74.jpg'
import Photo4 from '../../images/pumaEMJ/163a06ed751766.png'

function PumaEMJ () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const isMobile = window.innerWidth <= 500

    if (isMobile) {
        return (
            <><><><div className='PumaEMJ-page' style={{ backgroundColor: 'black' }}>
                <video controls={true} autoPlay={false} loop={true} playsinline="playsinline" className='my-5'
                    style={{
                        position: "relative",
                        width: "100%",
                        maxHeigth: "30%",
                        objectFit: "contain",
                        zIndex: "1"
                    }}>
                    <source src={VideoMobile} type="video/mp4" />
                </video>
            </div></><h1 className="gallery p-5 m-0">Photo gallery</h1><Carousel className="py-5" style={{ backgroundColor: "black" }}>

                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src={Photo1}
                            alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src={Photo2}
                            alt="Second slide" />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src={Photo3}
                            alt="Third slide" />
                    </Carousel.Item>
                </Carousel></><img className="d-block w-100" src={Photo4} alt="landing page" />
                
                <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brasil</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: Elas Movem Juntas</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>The Women’s Training campaign for PUMA Brasil, Elas Movem Juntas, aims to empower women to exercise for fun rather than solely for aesthetics. Featuring global model and actress Isis Valverde, young actress Larissa Manoela, influencer Dora Figueiredo, and Olympic sprinter Vitoria Rosa, the squad embodies fun, boldness, and unapologetic empowerment.<br/>
                As a graphic designer, my responsibility is to ensure that the visual identity of this campaign follows the same unity as PUMA globally. As an Associate Creative Director, I played a key role in driving the creative vision and ensuring the successful execution of the campaign from concept to delivery. Combining artistic talent with strategic thinking and leadership skills, I aimed to deliver impactful and memorable campaigns that resonate with the target audience.
                </text>
              </div>
            </div>
            </>
            
        )
    } else {
            return (
                <><><><div className='PumaEMJ-page' style={{ backgroundColor: 'black' }}>
                    <video controls={true} autoPlay={false} loop={true} playsinline="playsinline"
                        style={{
                            position: "relative",
                            width: "100%",
                            maxHeigth: "30%",
                            objectFit: "contain",
                            zIndex: "1"
                        }}>
                        <source src={Video} type="video/mp4" />
                    </video>
                </div></>

                    <h1 className="gallery d-flex justify-content-center py-5 m-0">Photo gallery</h1><Carousel className="py-5" style={{ backgroundColor: "black" }}>

                        <Carousel.Item interval={4000}>
                            <img
                                className="d-block w-50 mx-auto"
                                src={Photo1}
                                alt="First slide" />
                        </Carousel.Item>
                        <Carousel.Item interval={4000}>
                            <img
                                className="d-block w-50 mx-auto"
                                src={Photo2}
                                alt="Second slide" />
                        </Carousel.Item>
                        <Carousel.Item interval={4000}>
                            <img
                                className="d-block w-50 mx-auto"
                                src={Photo3}
                                alt="Third slide" />
                        </Carousel.Item>
                    </Carousel></>
                    <img className="w-100 pt-5" src={Photo4} alt="landing page" style={{ backgroundColor: "black" }}/>
                    
                    <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brasil</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: Elas Movem Juntas</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>The Women’s Training campaign for PUMA Brasil, Elas Movem Juntas, aims to empower women to exercise for fun rather than solely for aesthetics. Featuring global model and actress Isis Valverde, young actress Larissa Manoela, influencer Dora Figueiredo, and Olympic sprinter Vitoria Rosa, the squad embodies fun, boldness, and unapologetic empowerment.<br/>
                As a graphic designer, my responsibility is to ensure that the visual identity of this campaign follows the same unity as PUMA globally. As an Associate Creative Director, I played a key role in driving the creative vision and ensuring the successful execution of the campaign from concept to delivery. Combining artistic talent with strategic thinking and leadership skills, I aimed to deliver impactful and memorable campaigns that resonate with the target audience.
                </text>
              </div>
            </div>
            </>
            )
    }
}

export default PumaEMJ