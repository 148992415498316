import React from 'react'
import { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Video from '../../images/pumaMayze/PUMA_MAYZE_60s_16x9_MASTER.mp4'
import VideoMobile from '../../images/pumaMayze/Snapinsta_10000000_506076307810239_8571630264001872997_n.mp4'
import Photo1 from '../../images/pumaMayze/278355259_100448642645093_2810449839741701144_n.jpg'
import Photo2 from '../../images/pumaMayze/278899968_770416267257224_4191281877366040379_n.jpg'
import Photo3 from '../../images/pumaMayze/279062323_3162152564060462_1745201231914805408_n.jpg'
import Photo4 from '../../images/pumaMayze/279196978_2896363167330630_3555778772222612934_n.jpg'
import Photo5 from '../../images/pumaMayze/280579819_378657990880682_949031553977848604_n.jpg'
import Photo6 from '../../images/pumaMayze/285200743_5109604749152872_8198923549661396365_n.jpg'

import './PumaMayze.css'

function PumaMayze () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const isMobile = window.innerWidth <= 500
        
        if (isMobile) {
            return (
                <><div className='PumaMayze-page' style={{ backgroundColor: 'black' }}>
                    <video controls={true} autoPlay={false} loop={true} playsinline="playsinline" className='my-5'
                        style={{
                            position: "relative",
                            width: "100%",
                            maxHeigth: "30%",
                            objectFit: "contain",
                            zIndex: "1"
                        }}>
                        <source src={VideoMobile} type="video/mp4" />
                    </video>
                </div>
                
                <h1 className="gallery p-5 m-0">Photo gallery</h1><Carousel className="py-5" style={{ backgroundColor: "black" }}>

                        <Carousel.Item interval={4000}>
                            <img
                                className="d-block w-100"
                                src={Photo1}
                                alt="First slide" />
                        </Carousel.Item>
                        <Carousel.Item interval={4000}>
                            <img
                                className="d-block w-100"
                                src={Photo2}
                                alt="Second slide" />
                        </Carousel.Item>
                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-100"
                                src={Photo3}
                                alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-100"
                                src={Photo4}
                                alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-100"
                                src={Photo5}
                                alt="Third slide" />
                        </Carousel.Item>
                        <Carousel.Item  interval={4000}>
                            <img
                                className="d-block w-100"
                                src={Photo6}
                                alt="Third slide" />
                        </Carousel.Item>
                    </Carousel>
                    
                    <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brasil feat Bruna Marquezine</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: PUMA Mayze</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>The global fashionista and supermodel Bruna Marquezine stars in the PUMA Mayze campaign, alongside the Brazilian fashion icon. PUMA Mayze isn't just a pair of sneakers; it's a statement. It embodies the belief that every woman has the power to be who they want to be, and PUMA Mayze serves as the platform for that empowerment.<br/>
                As an Associate Creative Director, I played a pivotal role in shaping the creative vision and ensuring the seamless execution of the PUMA Mayze campaign from its inception to final delivery. Leveraging my artistic expertise, strategic insight, and leadership skills, I aimed to create compelling and unforgettable campaigns that deeply resonate with women in Brazil.
                </text>
              </div>
            </div>
            </>
                )
            } else {
                return (
                <><div className='PumaMayze-page pb-5' style={{ backgroundColor: 'black' }}>
                        <video controls={true} autoPlay={false} loop={true} playsinline="playsinline" className='pb-5'
                            style={{
                                position: "relative",
                                width: "100%",
                                maxHeigth: "30%",
                                objectFit: "contain",
                                zIndex: "1"
                            }}>
                            <source src={Video} type="video/mp4" />
                        </video>
                    </div>

    <h1 className="gallery d-flex justify-content-center p-5 m-0">Photo gallery</h1>

    <Carousel className="py-5" style={{backgroundColor: "black"}}>
      <Carousel.Item interval={4000}>
        <img
          className="d-block w-50 mx-auto" 
          src={Photo1}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <img
          className="d-block w-50 mx-auto" 
          src={Photo2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item  interval={4000}>
        <img
          className="d-block w-50 mx-auto" 
          src={Photo3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item  interval={4000}>
        <img
          className="d-block w-50 mx-auto" 
          src={Photo4}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item  interval={4000}>
        <img
          className="d-block w-50 mx-auto" 
          src={Photo5}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item  interval={4000}>
        <img
          className="d-block w-50 mx-auto" 
          src={Photo6}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>

    <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>PUMA Brasil feat Bruna Marquezine</h3>
              <h5 style={{marginTop: '20px'}}>Campaign: PUMA Mayze</h5>
              <h5>Services: Graphic Design/Art Direction/Associate Creative Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>The global fashionista and supermodel Bruna Marquezine stars in the PUMA Mayze campaign, alongside the Brazilian fashion icon. PUMA Mayze isn't just a pair of sneakers; it's a statement. It embodies the belief that every woman has the power to be who they want to be, and PUMA Mayze serves as the platform for that empowerment.<br/>
                As an Associate Creative Director, I played a pivotal role in shaping the creative vision and ensuring the seamless execution of the PUMA Mayze campaign from its inception to final delivery. Leveraging my artistic expertise, strategic insight, and leadership skills, I aimed to create compelling and unforgettable campaigns that deeply resonate with women in Brazil.
                </text>
              </div>
            </div>
                    </>
                )
             }
}

export default PumaMayze