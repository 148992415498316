import React, { useEffect } from 'react'

import SmcPage from '../../images/smc/web-mockup.png'

function Smc() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className='smc-page'>
            <img src={SmcPage} alt='smc images' style={{ width: '100vw' , height: 'auto' }} />

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>SMC</h3>
              <h5 style={{marginTop: '20px'}}>Project: Always On</h5>
              <h5>Services: Graphic Design / Art Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>SMC is not just an online media agency; it's a boundary-breaking force without borders. Our ethos is rooted in diversity, inclusivity, and contemporary pop culture. Through our vibrant social media platforms, we disseminate content with the mission to inform and enrich the world, blending memes, pop culture references, and critical thinking.<br/>
                Our branding reflects our dynamic spirit and modern approach. Vibrant colors, bold illustrations, and cutting-edge motion design from the 2020s define our visual identity. SMC is not just a media agency; it's a movement shaping the digital landscape and making the universe a better place to live.
                </text>
              </div>
            </div>
        </div>
    )
}

export default Smc
