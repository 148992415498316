import React from 'react'
import { useEffect } from 'react'

import LucaPage from '../../images/luca/LUCA_portfolio_page.png'
import LucaGif from '../../images/luca/LUCA-page-end.gif'

function Luca () {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='luca page'>
            <img src={LucaPage} alt='luca images' style={{ width: '100vw' , height: 'auto' }} />
            <img src={LucaGif} alt='luca images' style={{ width: '100vw' , height: 'auto' }} loading="lazy"/>

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>LUCA</h3>
              <h5 style={{marginTop: '20px'}}>Project: Logo Design / Branding</h5>
              <h5>Services: Graphic Design</h5>
              <div style={{marginTop: '20px'}}>
                <text>Evolution, diversity, and the origins of life serve as the cornerstone of LUCA's mission to educate and inspire. LUCA, an acronym for the Last Universal Common Ancestor, endeavors to demystify astrobiology in an unprecedented manner.<br/>
                Our branding concept embodies inclusivity, strength, and accessibility for all. The logo symbolizes Darwin's Tree of Life, with interlocking paths forming a puzzle where every individual finds their place. Each letter within the logo contributes to this unity, highlighting the interconnectedness of all living beings.
                </text>
              </div>
            </div>
        </div>
    )
}

export default Luca