import React from 'react'
import { useEffect } from 'react'

import TravelFleekIntro from '../../images/travelFleek/TF-Portfolio-page.png'
import Flowchart from '../../images/travelFleek/Flowchart-01.png'
import Demo from '../../images/travelFleek/travelfleek_skip_login.mp4'
import LogoFinal from '../../images/travelFleek/TF_2.gif'

import './TravelFleek.css'

function TravelFleek () {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='travelfleek-page' style={{ backgroundColor: '#E4E8ED' }}>
             <img src={TravelFleekIntro} alt='all images' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} />
             <img src={Flowchart} alt='all images' style={{ width: '100vw' , height: 'auto', paddingBottom: '10%'}} loading='lazy' />

         <div className='travelfleek-demo'>
         <video autoPlay={true} loop={true} muted="muted" playsInline="playsInline" loading="lazy"
         id='demo'>
         <source src={Demo} type="video/mp4" />
        </video>
        </div>

        <img src={LogoFinal} alt='all images' style={{ width: '100vw' , height: 'auto'}} loading='lazy' />
        
        </div>
    )
}

export default TravelFleek