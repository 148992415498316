import React, { useEffect } from 'react'

import RoversPage from '../../images/rovers/rovers-page.png'
import RoversGif from '../../images/rovers/Rovers_page.gif'

function Rover() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className='rovers-page'>
            <img src={RoversPage} alt='rovers images' style={{ width: '100vw' , height: 'auto' }} />
            <img src={RoversGif} alt='rovers images' style={{ width: '100vw' , height: 'auto' }} loading="lazy"/>
        </div>
    )
}

export default Rover
