import React from 'react'
import { useEffect } from 'react'

import Gif1 from '../../images/anis/01_anis.gif'
import Gif2 from '../../images/anis/02_anis.gif'
import Image3 from '../../images/anis/03_anis-page.png'

import MockupVideo from '../../images/anis/anis-site-mockup_4.mp4'

function Anis () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      return (
          <div className='anis-page' style={{ marginBottom: '-7px', 'backgroundColor': 'black' }}>

            {/* // LOGO COMENDO S */}
            <img src={Gif1} alt='mockup' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} loading="lazy"/>
            <img src={Gif2} alt='mockup' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}} loading="lazy"/>

            {/* // BACKGROUND COM TONAÇÕES UM POUCO DIFERENTE */}
            <img src={Image3} alt='mockup' style={{ width: '100vw' , height: 'auto', marginTop: '-1px'}}/>
            
            <video autoPlay={true} loop={true} muted="muted" playsinline="playsinline" loading="lazy"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1" }}>
                <source src={MockupVideo} type="video/mp4" />
            </video>

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>Anis Berlin</h3>
              <h5 style={{marginTop: '20px'}}>Project: Logo Design / Branding</h5>
              <h5>Services: Graphic Design / UX UI</h5>
              <div style={{marginTop: '20px'}}>
                <text>Anis Berlin embodies the delightful fusion of sweetness and wellness. As a functional Brazilian confectionery nestled in Berlin, our mission is to redefine the perception of treats, making them both delicious and health-conscious.<br/>
                Our branding concept revolves around the idea that indulgence can also be nourishing and enjoyable. Drawing inspiration from the vibrant hues of the Brazilian flag, our colorful palette reflects the lively spirit of our offerings.
                The tribal-inspired logo pays homage to Brazil's indigenous heritage, reimagined through a contemporary and minimalist lens. It serves as a symbol of our commitment to tradition, innovation, and holistic well-being.
                </text>
              </div>
            </div>

          </div>


      )
}

export default Anis