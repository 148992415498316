import React from 'react'
import { useEffect } from 'react'

import headerImg1 from './images/PumaxBruna_06_1394_R1.png'
import headerImg2 from './images/portifa-mobile.png'
import arrow from './images/Component 2 – 1.svg'


import './Header.css'

function Header() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isMobile = window.innerWidth <= 500

    if (isMobile) {
        return(
            <div>
                <div className='header'>
                    <img className='header-img-2' src={headerImg2} alt='header-img-2'/>
                </div>
            </div>
        )
    } else {
        return(
            <div>
                <div className='header'>
                    <img className='header-img-1' src={headerImg1} alt='header-img-1'/>
                </div>
                <img className='arrow' src={arrow} alt='arrow'/>     
            </div>
        )
    }
}

export default Header