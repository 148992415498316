import React from 'react'
import { useEffect } from 'react'

import PingoTopViewBlue from "../../images/pingoo/Pingoo-giff-4.mp4"
import PingoTopViewMoodBoard from "../../images/pingoo/Pingoo-giff-3.mp4"
import PingooWebView from "../../images/pingoo/pingoo-1.mp4"

function Pingoo () {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className='pingoo page' style={{ marginBottom: '-7px', 'backgroundColor': 'black' }}>
            <video autoPlay={true} loop={true} muted="muted" playsinline="playsinline"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1",
              marginBottom: "-1%"
            }}>
             <source src={PingoTopViewBlue} type="video/mp4" />
            </video>


            <video autoPlay={true} loop={true} muted="muted" playsinline="playsinline"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1",
              marginBottom: "-1%"
               }}>
             <source src={PingoTopViewMoodBoard} type="video/mp4" />
            </video>

            <video autoPlay={true} loop={true} muted="muted" playsinline="playsinline"
             style={{
              position: "relative",
              width: "100%", 
              maxHeigth: "30%",
              objectFit: "contain",
              zIndex: "1" }}>
             <source src={PingooWebView} type="video/mp4" />
            </video>

            <div style={{padding: '20px 15%', 'backgroundColor': 'black', color: 'white', fontFamily: 'Brandon', 'fontSize': '20px'}}>
              <h3 style={{marginTop: '20px'}}>Pingoo Casa</h3>
              <h5 style={{marginTop: '20px'}}>Project: Always On / DTC / Social</h5>
              <h5>Services: Creative Design / Art Direction</h5>
              <div style={{marginTop: '20px'}}>
                <text>Pingoo is an ecommerce brand dedicated to enhancing your home aesthetic with accessible products. As the Art Director, my role is to position Pingoo as an aspirational brand with added value, orchestrating photoshoots and forging partnerships with influencers and celebrities.<br/>
                Additionally, as a Creative Designer, my responsibility is to conceptualize campaigns and identify opportunities while ensuring consistent branding across all touchpoints.
                </text>
              </div>
            </div>
        </div>
    )
}

export default Pingoo